import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="main-footer">
      <div className="container" >
        <div className="pull-right d-none d-sm-inline-block">
            <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
          <li className="nav-item">
          <span className="nav-link"></span>
          </li>
          <li className="nav-item">
          <span className="nav-link"></span>
          </li>
        </ul>
        </div>
        &copy; 2019 <span >Crypto Market Cap</span>. All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
