import React from 'react';
import './Detail.css';
import News from '../HomeComponent/NewsComponent/News';
import Chart from './Chart/Chart';
import Banner from '../Banner/Banner';
import ServiceFactory from '../../services/ServiceFactory';
import { connect } from 'react-redux';
import allCoins from '../../services/mockServices/mocks/allCoins.json'

class Detail extends React.Component {
  constructor(props) {
    super(props)

    this.banner1 = {
      title: "",
      image: "/images/banner.gif",
      url: "https://www.bluehillmining.com"
    }

    this.priceService = ServiceFactory.PriceService()

    this.coin = this.props.match.params.coin
    //this.coin = "ETH"
    this.state = {
      nameFull: "",
      name: "",
      image: "",
      display: null
    }
  }

  componentDidMount() {
    this.getDetail()
  }

  getDetail() {
    this.priceService.multipleFull(this.coin).then( price => {
      //console.log(price)
      
      let coinInfo = {}
      Object.keys(allCoins.Data).forEach(key => {
        const coinName = allCoins.Data[key].Name
        if(coinName.toLowerCase() === this.coin.toLowerCase()){
           coinInfo = allCoins.Data[key]
        }
      });

      price = price.data.DISPLAY
      this.setState({
        ...this.state,
        nameFull: coinInfo.FullName,
        name: coinInfo.Name,
        image: coinInfo.ImageUrl,
        display: price[this.coin]
      })
    })
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12">
            <Banner ad={this.banner1}/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="box">
              <div className="box-header with-border">
                <img width="48px" src={"https://www.cryptocompare.com/" + this.state.image}/> <h4 className="ml-sm-2 box-title">{this.state.nameFull} - {this.state.name}</h4>
              </div>
              <div className="box-body">
                {this.state.display !== null && (
                <div className="row m-4 align-items-center">
                  <div className="col-sm-8 col-lg-auto text-success">
                    <h1 className="display-4">{this.state.display[this.props.currency].PRICE}</h1>
                  </div>
                  <div className="col-sm-2 text-success">
                    <h2><label className="label label-success"><i className="fa fa-chevron-up"></i> % {this.state.display[this.props.currency].CHANGEPCT24HOUR}</label></h2>
                  </div>
                  <div className="ml-auto col-md-12 col-lg-6  align-self-center">
                    <ul className="flexbox flex-justified text-center">
                      <li className="br-1 botder-light text-center">
                        <div className="font-size-18">{this.state.display[this.props.currency].MKTCAP}</div>
                        <small>Cap. Mkt.</small>
                      </li>
                      <li className="br-1 botder-light text-center">
                        <div className="font-size-18">{this.state.display[this.props.currency].VOLUME24HOUR}</div>
                        <small>Vol. 24H</small>
                      </li>
                      <li className="br-1 botder-light text-center">
                        <div className="font-size-18">{this.state.display[this.props.currency].OPEN24HOUR}</div>
                        <small>Apertura 24H</small>
                      </li>
                      <li className="text-center">
                        <div className="font-size-18">{this.state.display[this.props.currency].LOW24HOUR} / {this.state.display[this.props.currency].HIGH24HOUR}</div>
                        <small>Min/Max 24H</small>
                      </li>
                    </ul>
                  </div>
                </div>
                )}

                <div className="row">
                  <div className="col-12">
                    <Chart coin={this.coin} currency={this.props.currency}/>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="box">
              <div className="box-header with-border">
                <h4 className="box-title">Noticias</h4>
              </div>
              <div className="box-body">
                <News category={this.coin} />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ currency: state.currency.currency, prices: state.currency.prices })

export default connect(mapStateToProps)(Detail);
