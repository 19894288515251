import axios from 'axios'
import Service from './Service'

export default class HistoryServicee extends Service {

    daily(coin, currency, limit){
        const path = '/v1/history/daily'
        const query = {
            fsym: coin,
            tsym: currency,
            limit: limit,
        }
        return axios.get(this.getUrl(path, query))
    }

    hourly(coin, currency, limit){
        const path = '/v1/history/hourly'
        const query = {
            fsym: coin,
            tsym: currency,
            limit: limit,
        }
        return axios.get(this.getUrl(path, query))
    }


    minute(coin, currency, limit){
        const path = '/v1/history/minute'
        const query = {
            fsym: coin,
            tsym: currency,
            limit: limit,
        }
        return axios.get(this.getUrl(path, query))
    }

}