import React from 'react';
import './NavBar.css';
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux';
import setCurrencyAction from '../../redux/actions/setCurrencyAction';
import ServiceFactory from '../../services/ServiceFactory';
import currencies from '../../constants/currencies.json'

class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currencies: currencies,
      selectedCurrency: "USD"
    }

    this.topListService = ServiceFactory.TopListService()
  }
  componentDidMount(){
    this.setCurrency(this.state.selectedCurrency)
  }

  setCurrency(currency) {
    this.topListService.byMarketCap(currency, 100, 0).then( prices => {
      this.selectEnable = true
      if(Array.isArray(prices.data.Data)){
        this.setState({
          ...this.state,
          selectedCurrency: currency
        })
        this.props.dispatch(setCurrencyAction(currency, prices.data.Data))
      }
    })
  }
  
  render(){
    const currs = this.state.currencies.map((item, index) => {
      return (
        <a key={index} className="dropdown-item" href="javascript:void(0)" onClick={this.setCurrency.bind(this, item.ISO)}>{item.ISO} - {item.Symbol}</a>
      )
    })
    return (
      <header className="main-header">
        <Link to="/" className="logo">
          {/*<b className="logo-mini">
            <span className="light-logo"><img src="%PUBLIC_URL%/images/logo-light.png" alt="logo"></img></span>
            <span className="dark-logo"><img src="%PUBLIC_URL%/images/logo-dark.png" alt="logo"></img></span>
          </b>
          <span className="logo-lg">
            <img src="%PUBLIC_URL%/images/logo-light-text.png" alt="logo" className="light-logo"></img>
            <img src="%PUBLIC_URL%/images/logo-dark-text.png" alt="logo" className="dark-logo"></img>
          </span>*/} 
        </Link>
        <nav className="navbar navbar-static-top navbar-expand-lg navbar-light" style={{backgroundColor: "#FFF"}}>
          <div className="container" >
            <Link className="navbar-brand text-dark" to="/"><img src="/images/logo.jpg" height="30" alt="" loading="lazy"/></Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav col-auto" style={{width:"100%"}}>
                <li className="nav-item dropdown ml-auto">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.state.selectedCurrency}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {currs}
                  </div>
                </li>
                {/*<li className="nav-item">
                  <span className="nav-link" href="#"><Link to="/coin">Detail</Link></span>
        </li>*/}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
  
}

const mapStateToProps = state => ({ currency: state.currency })

export default connect(mapStateToProps)(NavBar);
