import React from 'react';
import './Prices.css';
import {
   Link
} from "react-router-dom";
import ServiceFactory from '../../../services/ServiceFactory';

class Prices extends React.Component {

   constructor(props) {
      super(props)
      this.priceService = ServiceFactory.PriceService()

      this.state = {
         page: 0,
         pageSize: 10,
         search: "",
         searchedCoins: [],
         showSearched: false
      }

      this.handleSearchChange = this.handleSearchChange.bind(this);
      this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
   }

   handlePageSizeChange(event) {
     this.setState({
        ...this.state,
        pageSize: event.target.value
      });
   }

   handleSearchChange(event) {
      this.setState({
        ...this.state,
        search: event.target.value,
      });
   }

   search() {
      if(this.state.search === ""){
         this.setState({
            ...this.state,
            search:"",
            searchedCoins: [],
            showSearched: false
         })
      } else if(this.state.search.length >= 2) {
         const query = this.state.search
         this.priceService.search(query).then(prices => {
            const result = prices.data
            this.setState({
               ...this.state,
               searchedCoins: result.sort((a,b) => b.RAW[this.props.currency].MKTCAP - a.RAW[this.props.currency].MKTCAP),
               showSearched: true
            })
         })
      }
   }

   nextPage() {
      this.setState({
         ...this.state,
         page: this.state.page+1
      })
   }

   previousPage() {
      this.setState({
         ...this.state,
         page: this.state.page-1
      })
   }

   goToPage(page) {
      this.setState({
         ...this.state,
         page: page-1
      })
   }

   render() {
      let pricesToShow = []
      if( this.state.showSearched ){
         pricesToShow = this.state.searchedCoins
      } else {
         pricesToShow = this.props.prices
      }
      const start = this.state.page*this.state.pageSize
      const size = this.state.page*this.state.pageSize + this.state.pageSize
      const slicePrices = pricesToShow.slice(start, size)
      const priceRows = slicePrices.filter(item => item.DISPLAY[this.props.currency] !== undefined).map((item, index) => {
         return (
            <tr key={index} role="row">
               <td>
                  <span>
                     <Link to={"/detail/" + item.CoinInfo.Name} >
                        <img width="48px" src={"https://www.cryptocompare.com/" + item.CoinInfo.ImageUrl} />
                     </Link>
                  </span>
               </td>
               <td>
                  <small><Link to={"/detail/" + item.CoinInfo.Name} className="text-yellow hover-warning">{item.CoinInfo.FullName}</Link></small>
                  <h6 className="text-muted">{item.CoinInfo.Name}</h6>
               </td>
               <td className="text-right"><p> {item.DISPLAY[this.props.currency].PRICE}</p></td>
               <td className="text-right"><p> {item.DISPLAY[this.props.currency].MKTCAP}</p></td>
               <td className="text-right"><p> {item.DISPLAY[this.props.currency].VOLUME24HOURTO}</p></td>
               <td className="no-wrap text-right">
                  <label className={"label " + (item.DISPLAY[this.props.currency].CHANGEPCTHOUR >= 0 ? "label-success" : "label-danger")}>
                     <i className={"fa fa-chevron-" + (item.DISPLAY[this.props.currency].CHANGEPCTHOUR >= 0 ? "up" : "down")}></i>
                     % {item.DISPLAY[this.props.currency].CHANGEPCTHOUR}
                  </label>
               </td>
               <td className="no-wrap text-right">
                  <label className={"label " + (item.DISPLAY[this.props.currency].CHANGEPCT24HOUR >= 0 ? "label-success" : "label-danger")}>
                     <i className={"fa fa-chevron-" + (item.DISPLAY[this.props.currency].CHANGEPCT24HOUR >= 0 ? "up" : "down")}>
                     </i>
                      % {item.DISPLAY[this.props.currency].CHANGEPCT24HOUR}
                  </label>
               </td>
               <td className="no-wrap text-right">
                  <label className={"label " + (item.DISPLAY[this.props.currency].CHANGEPCTDAY >= 0 ? "label-success" : "label-danger")}>
                     <i className={"fa fa-chevron-" + (item.DISPLAY[this.props.currency].CHANGEPCTDAY >= 0 ? "up" : "down")}></i>
                     % {item.DISPLAY[this.props.currency].CHANGEPCTDAY}
                  </label>
               </td>
               {/* <td><Link to="/"><span className="sparklines">8,4,0,0,1,4,4,10,10,0,0,4,6,5,9,10</span></Link></td> */}
            </tr>
         )
      })
      const pageButtons = []
      for(let i=1; i<=pricesToShow.length/this.state.pageSize; i++){
         pageButtons.push(
            <li key={i} className={"page-item" + (this.state.page === i-1 ? " active" : "")}><a className="page-link" href="javascript:void(0)" onClick={this.goToPage.bind(this, i)}>{i}</a></li>
         )
      }
      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col-auto mr-auto">
                  <select className="form-control" value={this.state.pageSize} onChange={this.handlePageSizeChange}>
                     <option value="10">10</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                  </select>
               </div>
               <div className="col-auto my-2">
                  <div className="form-inline">
                     <input className="form-control mr-sm-2" type="search" placeholder="Buscar (Min. 2 letras)" aria-label="Buscar" value={this.state.search} onChange={this.handleSearchChange}/>
                     <button className={ "btn btn-lg btn-outline-" + (this.state.showSearched ? "success" : "success") } type="submit" onClick={this.search.bind(this)}>{ this.state.showSearched ? "Search" : "Search" }</button>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <div className="table-responsive">
                     <table className="table table-bordered dataTable no-footer" id="dataTable_crypto" role="grid">
                        <thead>
                           <tr role="row">
                              <th colSpan="2" rowSpan="1">Moneda</th>
                              <th className="text-right">Precio</th>
                              <th className="text-right">Capital de mercado</th>
                              <th className="text-right">Volumen 24H</th>
                              <th className="text-right">Cambio % (1H)</th>
                              <th className="text-right">Cambio % (24H)</th>
                              <th className="text-right">Cambio % (7D)</th>
                              {/* <th className="text-right">Chart</th> */}
                           </tr>
                        </thead>
                        <tbody>
                           {priceRows}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div className="row justify-content-end">
               <div className="col-auto">
                  <nav aria-label="...">
                     <ul className="pagination">
                        <li className={"page-item" + (this.state.page === 0 ? " disabled" : "")}>
                           <a className="page-link" onClick={this.previousPage.bind(this)}>Previous</a>
                        </li>
                        {pageButtons}
                        <li className={"page-item" + (this.state.page+1 === this.props.prices.length/this.state.pageSize ? " disabled" : "")}>
                           <a className="page-link" onClick={this.nextPage.bind(this)}>Next</a>
                        </li>
                     </ul>
                  </nav>
               </div>
            </div>
         </div>
      );
   }

}

export default Prices;
