const currencyReducer = (state = {currency: "USD", prices: []}, {type, payload}) => {
    switch(type) {
        case "SET_CURRENCY": {
            return payload;
        }
        default: {
            return state;
        }
    }
}

export default currencyReducer;