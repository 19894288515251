import HistoryMocks from "./mockServices/HistoryMocks";
import NewsMocks from "./mockServices/NewsMocks";
import PriceMocks from "./mockServices/PriceMocks";
import TopListMocks from "./mockServices/TopListMocks";
import InfoMocks from "./mockServices/InfoMocks";

import HistoryService from "./HistoryService";
import NewsService from "./NewsService";
import PriceService from "./PriceService";
import TopListService from "./TopListService";
import InfoService from "./InfoService";

const MOCKS = false

export default {

    HistoryService() {
        if(MOCKS)
            return new HistoryMocks()
        return new HistoryService()
    },

    NewsService() {
        if(MOCKS)
            return new NewsMocks()
        return new NewsService()
    },

    PriceService() {
        if(MOCKS)
            return new PriceMocks()
        return new PriceService()
    },

    TopListService() {
        if(MOCKS)
            return new TopListMocks()
        return new TopListService()
    },

    InfoService() {
        if(MOCKS)
            return new InfoMocks()
        return new InfoService()
    },

}