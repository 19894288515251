import React from 'react';
import './Chart.css';
import ServiceFactory from '../../../services/ServiceFactory';

class Chart extends React.Component {
  constructor(props) {
    super(props)
    this.historyService = ServiceFactory.HistoryService()
    this.coin = this.props.coin
    this.state = {
      freq: "h"
    }

    this.oldFreq = ""
    this.oldCurrency = ""
  }

  componentDidMount() {
    this.getHistory("d")
  }
  
  getHistory(freq){
    this.setState({
      ...this.state,
      freq: freq
    })
  }

  updateChart(freq){
    if(this.oldFreq !== freq || this.oldCurr !== this.props.currency) {
      if(freq === "m"){
        this.historyService.minute(this.coin, this.props.currency, 720).then( history => {
          this.setChart(history.data.Data.Data)
        })
      } else if ( freq === "h" ) {
        this.historyService.hourly(this.coin, this.props.currency, 720).then( history => {
          this.setChart(history.data.Data.Data)
        })
      } else {
        this.historyService.daily(this.coin, this.props.currency, 720).then( history => {
          this.setChart(history.data.Data.Data)
        })
      }
    }
    this.oldFreq = freq
    this.oldCurr = this.props.currency
  }

  setChart(data) {
    const ohcl = data.map((item, index) => {
      return {
        date: new Date(item.time*1000),
        open: item.open,
        close: item.close,
        high: item.high,
        low: item.low,
        volume: item.volumeto,
        value: item.close
      }
    })
    window.setChart(ohcl)
  }

  render() {
    this.updateChart(this.state.freq)
    return (
      <div className="container-fluid b-1 p-4">
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            {/*<button type="button" className={"btn " + (this.state.freq === "m" ? "btn-success" : "btn-outline-success")} onClick={this.getHistory.bind(this,"m")}>Minute</button>*/}
            <button type="button" className={"m-1 btn " + (this.state.freq === "h" ? "btn-success" : "btn-outline-success")}onClick={this.getHistory.bind(this, "h")}>Hour</button>
            <button type="button" className={"m-1 btn " + (this.state.freq === "d" ? "btn-success" : "btn-outline-success")} onClick={this.getHistory.bind(this, "d")}>Day</button>
          </div>
          <div className="col-sm-12 col-lg-6">
            
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="chart">
              <div id="chartdiv1" style={{height: '500px'}}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chart;
