import axios from 'axios'
import Service from './Service'

export default class InfoService extends Service {

    allExchanges(){
        const path = '/v1/info/exchanges'
        const query = {}
        return axios.get(this.getUrl(path, query))
    }


}