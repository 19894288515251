import React from 'react';
import './Ticker.css';
import { Link } from 'react-router-dom';

class Ticker extends React.Component {

  componentDidMount() {
    //console.log(this.props.prices)
    window.setupTickers()
  }
  
  componentDidUpdate() {
    window.setupTickers()
  }

  render() {
    const tickers = this.props.prices.slice(0, 20).map((price, index) => {
      return (
        <li key={index} className="br-1">
          <Link to={"/detail/" + price.CoinInfo.Name} >
            <img width="24px" src={"https://www.cryptocompare.com/" + price.CoinInfo.ImageUrl} />
            <span className="ml-1 mr-1">{price.CoinInfo.Name}</span>
            <span className={price.RAW[this.props.currency].CHANGE24HOUR.toFixed(2) >= 0 ? "text-success" : "text-danger"}>{price.RAW[this.props.currency].CHANGE24HOUR.toFixed(2)} </span>
          </Link>
        </li>
      )
    })
    return (
      <div className="box">
        <div className="box-body tickers-block">
          <ul id="webticker-1">
            {tickers}
          </ul>
        </div>
      </div>
    );
  }
}


export default Ticker;
