import React from 'react';
import './Exchangers.css';
import ServiceFactory from '../../../services/ServiceFactory';

class Exchangers extends React.Component {

  constructor(props) {
    super(props)
    this.infoService = ServiceFactory.InfoService()
    this.state = { exchangers: {} }
  }

  componentDidMount() {
    this.infoService.allExchanges().then(exchangers => {
      //console.log(exchangers.data)
      this.setState({
        exchangers: exchangers.data.Data
      })
    })
  }

  componentDidUpdate() {
    //console.log("UPDATE")
    if(Object.keys(this.state.exchangers).length > 0) {
      window.setExchangerDataTable()
    }
  }

  render() {
    const exchangers = []

    Object.keys(this.state.exchangers).sort((a, b)=> { return this.state.exchangers[b].GradePoints - this.state.exchangers[a].GradePoints }).slice(0, 100).forEach((item, index) => {
      exchangers.push(
        <tr key={index} role="row">
          <td><img width="48px" src={"https://www.cryptocompare.com/" + this.state.exchangers[item].LogoUrl} /></td>
          <td><h4>{this.state.exchangers[item].Name}</h4></td>
          <td>{this.state.exchangers[item].DISPLAYTOTALVOLUME24H.BTC}</td>
          <td>{this.state.exchangers[item].Grade}</td>
          <td>{this.state.exchangers[item].GradePoints}</td>
        </tr>
      )
    })
    return (
      <div className="container p-0">
        <div className="row">
          <div className="col-12">
            <div className="box m-0">
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-bordered dataTable no-footer" id="exchangers" role="grid">
                    <thead>
                      <tr role="row">
                        <th colSpan="2" rowSpan="1">Exchange</th>
                        <th>Volumen 24H</th>
                        <th>Grado</th>
                        <th>Puntos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exchangers}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default Exchangers;
