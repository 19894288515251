import axios from 'axios'
import Service from './Service'

export default class TopListService extends Service {

    byVol(currency, limit, page){
        const path = '/top/totalvolfull'
        const query = {
            tsym: currency,
            limit: limit,
            page: page,
        }
        return axios.get(this.getUrl(path, query))
    }

    byVolFull(currency, limit, page){
        const path = '/top/totaltoptiervolfull'
        const query = {
            tsym: currency,
            limit: limit,
            page: page,
        }
        return axios.get(this.getUrl(path, query))
    }

    byMarketCap(currency, limit, page){
        const path = '/v1/top/mktcapfull'
        const query = {
            tsym: currency,
            limit: limit,
            page: page,
        }
        return axios.get(this.getUrl(path, query))
    }

    ExchangesByVol(coin, currency){
        const path = '/v1/top/exchanges'
        const query = {
            fsym: coin,
            tsym: currency,
        }
        return axios.get(this.getUrl(path, query))
    }

    ExchangesByVolFull(coin, currency){
        const path = '/v1/top/exchangesByVolFull'
        const query = {
            fsym: coin,
            tsym: currency,
        }
        return axios.get(this.getUrl(path, query))
    }


}