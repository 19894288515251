import React from 'react'
import { Link } from 'react-router-dom'

class CoinCard extends React.Component {

  render() {
    return (
      <div className="box box-body pull-up" onClick={() => { window.location = "/detail/" + this.props.item.CoinInfo.Name }}>
        <div className="media align-items-center p-0">
          <div className="text-center">
            <Link to={"/detail/"+this.props.item.CoinInfo.Name} ><img width="72px"  src={"https://www.cryptocompare.com/" + this.props.item.CoinInfo.ImageUrl} /></Link>
          </div>
          <div>
            <h2 className="no-margin">{this.props.item.CoinInfo.NameFull} {this.props.item.CoinInfo.Name}</h2>
          </div>
        </div>
        <div className="flexbox align-items-center mt-25">
          <div>
            <p className="no-margin">{this.props.item.DISPLAY[this.props.currency].PRICE} </p>
          </div>
          <div className="text-right">
            <p className="no-margin"><span className={this.props.item.DISPLAY[this.props.currency].CHANGEPCT24HOUR >= 0 ? "text-success" : "text-danger"}>% {this.props.item.DISPLAY[this.props.currency].CHANGEPCT24HOUR}</span></p>
          </div>
        </div>
      </div>
    )
  }
}

export default CoinCard