import React from 'react';
import './Home.css';
import Prices from './PricesComponent/Prices';
import News from './NewsComponent/News';
import Exchangers from './ExchangersComponent/Exchangers';
import Ticker from '../TickerComponent/Ticker';
import Banner from '../Banner/Banner';
import CoinCard from './CoinCard';
import { connect } from 'react-redux';
//import ServiceFactory from '../../services/ServiceFactory';

class Home extends React.Component {

  constructor(props) {
    super(props)
    //this.topListService = ServiceFactory.TopListService()
    //this.currency = ""
    
    this.banner1 = {
      title: "",
      image: "/images/banner.gif",
      url: "https://www.bluehillmining.com"
    }

    this.banner2 = {
      title: "",
      image: "/images/banner.gif",
      url: "https://www.bluehillmining.com"
    }

    this.state= {
      prices: []
    }
  }

  /*componentDidMount() {
     if(this.props.prices.length > 0) {
        window.setPriceDataTable()
     }
  }*/

  render() {
    const currency = this.props.currency

    const formatPrices = this.props.prices.filter(item => item.RAW !== undefined && item.DISPLAY !== undefined)

    const coinCards = formatPrices.slice(0, 20)
    .sort( (a, b) => { 
      return Math.abs(b.RAW[currency].CHANGEPCT24HOUR) - Math.abs(a.RAW[currency].CHANGEPCT24HOUR)
    })
    .slice(0, 4)
    .map((item, index) => {
      return (
        <div key={index} className="col-sm-6 col-lg-3">
          <CoinCard key={index} item={item} currency={currency}/>
        </div>
      )
    })
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <Banner ad={this.banner1}/>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <Ticker prices={formatPrices} currency={currency}/>
          </div>
        </div>
        <div className="row mt-4">
          {coinCards}
        </div>
        <div className="row mt-4">
          <div className="col-md-12 col-lg-12">
            <ul className="nav nav-pills nav-justified" role="tablist">
              <li className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#prices" role="tab"><span className="hidden-sm-up"><i className="ion-home"></i></span> <span className="hidden-xs-down">Monedas</span></a> </li>
              <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#news" role="tab"><span className="hidden-sm-up"><i className="ion-person"></i></span> <span className="hidden-xs-down">Noticias</span></a> </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active show" id="prices" role="tabpanel">
                <div className="box m-0">
                  <div className="box-body">
                    <Prices prices={formatPrices} currency={currency} />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="news" role="tabpanel">
                <div className="box m-0">
                  <div className="box-header with-border">
                    <h5 className="box-title">Articulos recientes</h5>
                  </div>
                  <div className="box-body">
                    <News category="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 col-lg-12">
            <Banner ad={this.banner2}/>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 col-lg-12">
            <div className="box box-default">
              <div className="box-header">
                <h3 className="box-title">Exchangers</h3>
              </div>
              <div className="box-body p-0">
                <Exchangers />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => ({ currency: state.currency.currency, prices: state.currency.prices })

export default connect(mapStateToProps)(Home);
