import React from 'react';
import './App.css';
import Home from './HomeComponent/Home';
import NavBar from './NavBarComponent/NavBar';
import Footer from './FooterComponent/Footer';
import Detail from './DetailComponent/Detail';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <NavBar />
          <div className="content-wrapper">
            <section className="content">
              <div>
                <Switch>
                  <Route path="/detail/:coin" component={Detail}/>
                  <Route path="/" component={Home}/>
                </Switch>
              </div>
            </section>
          </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
