import axios from 'axios'
import Service from './Service'

export default class NewsService extends Service {

    latest(categories){
        const path = '/v1/news/latest'
        const query = {
            lang: "ES",
            categories: categories.join(","),
        }
        return axios.get(this.getUrl(path, query))
    }


}