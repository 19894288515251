import axios from 'axios'
import Service from './Service'
import currencies from '../constants/currencies.json'

export default class PriceService extends Service {

    single(coin){
        const path = '/v1/prices/single'
        const query = {
            fsym: coin,
            tsyms: currencies.map(item => item.ISO).join(","),
        }
        return axios.get(this.getUrl(path, query))
    }

    multiple(coin){
        const path = '/v1/prices/multiple'
        const query = {
            fsyms: coin,
            tsyms: currencies.map(item => item.ISO).join(","),
        }
        return axios.get(this.getUrl(path, query))
    }

    multipleFull(coin){
        const path = '/v1/prices/multipleFull'
        const query = {
            fsyms: coin,
            tsyms: currencies.map(item => item.ISO).join(","),
        }
        return axios.get(this.getUrl(path, query))
    }

    search(search){
        const path = '/v1/prices/search'
        const query = {
            search: search,
        }
        return axios.get(this.getUrl(path, query))
    }


}