import React from 'react';
import './News.css';
import ServiceFactory from '../../../services/ServiceFactory';

class News extends React.Component {

  constructor(props) {
    super(props)
    this.newsService = ServiceFactory.NewsService() 
    this.state = { 
      news: [],
      expand: false 
    }
    
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  componentDidMount() {
    this.newsService.latest([this.props.category]).then( news => {
      //console.log(news)
      this.setState({
        news: news.data.Data
      })
    })
  }

  toggleExpand() {
    this.setState({
      ...this.state,
      expand: !this.state.expand
    })
  }


  render() {
    const news = this.state.news.slice(0, this.state.expand ? this.state.news.length : Math.min(10, this.state.news.length) ).map( (news, index) => {
      return (
        <a key={index} className="media media-single" href={news.url} target="_blank" rel="noopener noreferrer">
          <img className="w-80" src={news.imageurl} alt="..."/>
          <div className="media-body">
            <h6>{news.title}</h6>
            <small className="text-fader">{news.source}</small>
          </div>
        </a>
      )
    })
    return (
      <div>
        <div className="media-list media-list-hover media-list-divided">
          {news}
        </div>
        <div className="text-center bt-1 border-light p-2 m-auto">
          <span type="button" className="btn btn-link text-uppercase d-block font-size-12" onClick={this.toggleExpand}>
            {"Ver " + (this.state.expand ? "" : "todos") + " los articulos recientes"}
          </span>
        </div>
      </div>
    );
  }
  
}

export default News;
