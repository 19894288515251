import React from 'react';
import './Banner.css';
import { Link } from 'react-router-dom';

class Banner extends React.Component {
  render() {
    //console.log(this.props)
    return (
      <a href={this.props.ad.url} target="_blank" rel="noopener noreferrer"> <div className="box card-inverse text-center py-10" data-overlay="5" style={
        {
          "height": "180px",
          "backgroundImage": "url(" + this.props.ad.image + ")",
          "backgroundRepeat": "no-repeat",
          "backgroundSize": "cover"
        }
      }>
        <div className="card-body">
          <h3>{this.props.ad.title}</h3>
          <br /><br />
          {/*<a className="btn btn-outline no-radius btn-light btn-default" href={this.props.ad.url} target="_blank" rel="noopener noreferrer">Leer mas</a>*/}
        </div>
      </div>
      </a>
    );
  }
}

export default Banner;
