
export default class Service {

    constructor() {
        this.baseUrl = "/api"
    }

    getUrl(path, query) {
        const params = []
        Object.keys(query).forEach(key => {
            if(query[key].toString().length > 0) {
                params.push(key+"="+query[key])
            }
        })
        return `${this.baseUrl}${path}?${params.join("&")}`
    }

}