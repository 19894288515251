import { createStore } from 'redux'
import { combineReducers } from 'redux'
import currencyReducer from './reducers/currencyReducer'

const reducers = combineReducers({
    currency: currencyReducer
});

const store = createStore(reducers);

export default store;